<div class="col gap1"
>
  <h1 mat-dialog-title>Create new rack</h1>
  
  <mat-dialog-content class="col gap1">
    <p style="font-size: 1rem">
      You can always edit the rack name and number of rows later.
    </p>
    <lib-mat-form-entity [type]="fields.name.type"
                         [control]="fields.name.control"
                         [label]="fields.name.label"
    >
    </lib-mat-form-entity>
    <lib-mat-form-entity [type]="fields.hp.type"
                         [control]="fields.hp.control"
                         [label]="fields.hp.label"
    >
    </lib-mat-form-entity>
    <lib-mat-form-entity [type]="fields.rows.type"
                         [control]="fields.rows.control"
                         [label]="fields.rows.label"
    >
    </lib-mat-form-entity>
    
    <div>Total HP for modules: {{fields.hp.control.value * fields.rows.control.value}}</div>
  
  </mat-dialog-content>
  <mat-dialog-actions style="border-top: 0.5rem solid rgba(13,112,35,0.4)">
    <div fxFlex
         fxLayout="row"
         fxLayoutGap="2rem"
         fxLayoutGap.xs="0rem"
    >
      <div fxFlex="grow"></div>
      <app-brand-primary-button
          theme="positive"
          (click$)="save$.next()"
          [disabled]="!formGroup.valid"
      >
        Create
      </app-brand-primary-button>
    </div>
  
  </mat-dialog-actions>
</div>