<div class="col root">
  <div class="row gap0 col-lt-MD"
  >
    <app-module-minimal [data]="data.a.module"
                        [viewConfig]="viewConfig"
                        class="module"
    
    >
      <mat-chip-listbox>
        <app-module-cvitem [data]="data.a"
                           kind="out"
        ></app-module-cvitem>
      </mat-chip-listbox>
    </app-module-minimal>
    <app-patch-connection-symbol></app-patch-connection-symbol>
    <app-module-minimal [data]="data.b.module"
                        [viewConfig]="viewConfig"
                        class="module"
    >
      <mat-chip-listbox>
        <app-module-cvitem [data]="data.b"
                           kind="in"
        ></app-module-cvitem>
      </mat-chip-listbox>
    </app-module-minimal>
  </div>
  
  <lib-mat-form-entity [type]="types.AREA"
                       [control]="notes.control"
                       label="Notes"
                       [disabled]="!isEditing"
                       *ngIf="isEditing&&!isCreator"
                       [hint]="notes.control.value.length>900?notes.control.value.length+'/999':undefined"
                       [ngClass]="{'padT0':notes.control.value.length>0}"
  ></lib-mat-form-entity>
  
  <ng-container *ngIf="!isEditing">
    <div class="row gap0 padT0"
    >
        <ng-container *ngIf="data.notes&&data.notes.length>0">
          <div class="notes">
            {{ data.notes }}
          </div>
        </ng-container>
      
      <div style="flex-grow: 1">
        <ng-container *ngTemplateOutlet="ordinal"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isEditing">
    <div class="row gap0 padT0 space-between parent">
      
      <mat-icon [matTooltip]="isCreator?'Clear selection':'Remove connection'"
                (click)="remove$.next(data)"
                class="transparentize-quarter clickable opacizeOnHover deleteIcon"
      >delete
      </mat-icon>
      <app-brand-primary-button *ngIf="isCreator"
                                (click$)="create$.next(data)"
      >
        Confirm connection
      </app-brand-primary-button>
      <ng-container *ngTemplateOutlet="ordinal"></ng-container>
    </div>
  </ng-container>
  
  <ng-template #ordinal>
    <mat-card-subtitle *ngIf="index"
                       fxLayoutAlign="end center"
                       class="monospace"
                       [ngClass]="index%5!=0?'fade-ordinal':''"
    >#{{ index }}
    </mat-card-subtitle>
  </ng-template>
  <ng-template #notesT>
  </ng-template>
</div>