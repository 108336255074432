<div class="col gap0">
  <app-hero-clickable-title [link]="['/patches/details',data.id]"
                            infoBox
                            infoText="Patch name"
  >{{ data.name }}
  </app-hero-clickable-title>
  <app-entity-author [data]="data.author"></app-entity-author>
  
  <app-timestamps-relative [data]="data"
                           *ngIf="!viewConfig.hideDates"
  ></app-timestamps-relative>
  <div class="row gap1 space-between description"
       *ngIf="!viewConfig.hideDescription"
  >
    <mat-card-subtitle>{{ data.description }}</mat-card-subtitle>
  </div>

</div>
<!--{{viewConfig|json}}-->