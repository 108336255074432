"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AbstractProgram = void 0;
/**
 * Sigma.js WebGL Renderer Program
 * ================================
 *
 * Class representing a single WebGL program used by sigma's WebGL renderer.
 * @module
 */
var utils_1 = require("../../shaders/utils");
/**
 * Abstract Program class.
 *
 * @constructor
 */
var AbstractProgram = /** @class */function () {
  function AbstractProgram(gl, vertexShaderSource, fragmentShaderSource, points, attributes) {
    this.array = new Float32Array();
    this.points = points;
    this.attributes = attributes;
    this.gl = gl;
    this.vertexShaderSource = vertexShaderSource;
    this.fragmentShaderSource = fragmentShaderSource;
    var buffer = gl.createBuffer();
    if (buffer === null) throw new Error("AbstractProgram: error while creating the buffer");
    this.buffer = buffer;
    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    this.vertexShader = (0, utils_1.loadVertexShader)(gl, this.vertexShaderSource);
    this.fragmentShader = (0, utils_1.loadFragmentShader)(gl, this.fragmentShaderSource);
    this.program = (0, utils_1.loadProgram)(gl, [this.vertexShader, this.fragmentShader]);
  }
  AbstractProgram.prototype.bufferData = function () {
    var gl = this.gl;
    gl.bufferData(gl.ARRAY_BUFFER, this.array, gl.DYNAMIC_DRAW);
  };
  AbstractProgram.prototype.allocate = function (capacity) {
    this.array = new Float32Array(this.points * this.attributes * capacity);
  };
  AbstractProgram.prototype.hasNothingToRender = function () {
    return this.array.length === 0;
  };
  return AbstractProgram;
}();
exports.AbstractProgram = AbstractProgram;