<div class="col gap1"
>
  <lib-hero-content-card titleBig="Racks"
                         class="racksBG"
                         description="Browse and discover racks, get GAS and iterate on your setup"
  >
    <div class="col"
    >
      <div fxLayout="row"
           fxLayout.lt-sm="column"
           fxLayoutGap="1rem"
      >
        <lib-mat-form-entity [control]="dataService.fields.search.control"
                             [type]="dataService.formTypes.TEXT"
                             [label]="dataService.fields.search.label"
        ></lib-mat-form-entity>
        
        <lib-mat-form-entity [control]="dataService.fields.order.control"
                             [options$]="dataService.fields.order.options$"
                             [type]="dataService.formTypes.SELECT"
                             [disableVoidSelection]="true"
                             [label]="dataService.fields.order.label"
        ></lib-mat-form-entity>
  
        <div fxFlex="grow"></div>
      </div>
      
      <app-rack-list [data$]="dataService.racksList$"
                     [viewConfig]="viewConfig"
      ></app-rack-list>
      <mat-paginator [hidePageSize]="(dataService.serversideAdditionalData.itemsCount$|async)<20"
                     [pageSizeOptions]="[20,25,50,100]"
                     [length]="dataService.serversideAdditionalData.itemsCount$|async"
                     (page)="dataService.onPageEvent($event)"
                     showFirstLastButtons
                     style="margin-top:1rem;"
      ></mat-paginator>
      <lib-auto-update-loading-indicator [data$]="dataService.racksList$"
                                         [updateData$]="dataService.updateRacksList$"
      ></lib-auto-update-loading-indicator>
    </div>
  </lib-hero-content-card>
</div>