<lib-hero-content-card titleNormal="Comments"
>
  <ng-container *ngIf="{
data:(dataService.comments$|async),
user:(userManagerService.loggedUser$|async),

} as bag"
  >
    <lib-auto-update-loading-indicator [data$]="dataService.comments$"
                                       [updateData$]="dataService.requestCommentsUpdate$"
    >
      <div class="col gap2"
      >
        <app-comments-item-block [data]="bag.data"
        
        ></app-comments-item-block>
        
        <ng-container *ngIf="bag.data&&bag.user"
        >
          <lib-mat-form-entity [dataPack]="dataService.fields.submit"
                               [hint]="dataService.fields.submit.control.value.length<dataService.maxLength/3
                                   ?'':dataService.fields.submit.control.value.length+' /'+dataService.maxLength"
          ></lib-mat-form-entity>
          <div style="width: 100%; align-self: flex-end"
          >
            <app-brand-primary-button
              [disabled]="!dataService.fields.submit.control.valid||(!dataService.fields.submit.control.value.length>0)"
              (click$)="dataService.submitComment$.next(dataService.fields.submit.control.value)"
              [innerFlex]="'100%'"
            >Submit comment
            </app-brand-primary-button>
          </div>
        </ng-container>
      
      
      </div>
    </lib-auto-update-loading-indicator>
  </ng-container>


</lib-hero-content-card>