"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cubicInOut = exports.cubicOut = exports.cubicIn = exports.quadraticInOut = exports.quadraticOut = exports.quadraticIn = exports.linear = void 0;
/**
 * Sigma.js Easings
 * =================
 *
 * Handy collection of easing functions.
 * @module
 */
var linear = function (k) {
  return k;
};
exports.linear = linear;
var quadraticIn = function (k) {
  return k * k;
};
exports.quadraticIn = quadraticIn;
var quadraticOut = function (k) {
  return k * (2 - k);
};
exports.quadraticOut = quadraticOut;
var quadraticInOut = function (k) {
  if ((k *= 2) < 1) return 0.5 * k * k;
  return -0.5 * (--k * (k - 2) - 1);
};
exports.quadraticInOut = quadraticInOut;
var cubicIn = function (k) {
  return k * k * k;
};
exports.cubicIn = cubicIn;
var cubicOut = function (k) {
  return --k * k * k + 1;
};
exports.cubicOut = cubicOut;
var cubicInOut = function (k) {
  if ((k *= 2) < 1) return 0.5 * k * k * k;
  return 0.5 * ((k -= 2) * k * k + 2);
};
exports.cubicInOut = cubicInOut;
var easings = {
  linear: exports.linear,
  quadraticIn: exports.quadraticIn,
  quadraticOut: exports.quadraticOut,
  quadraticInOut: exports.quadraticInOut,
  cubicIn: exports.cubicIn,
  cubicOut: exports.cubicOut,
  cubicInOut: exports.cubicInOut
};
exports.default = easings;