<ng-container *ngIf="{
data:(dataService.singleRackData$|async),
isCurrentRackEditable:(dataService.isCurrentRackEditable$|async),
isCurrentRackPropertyOfCurrentUser:(dataService.isCurrentRackPropertyOfCurrentUser$|async),
} as bag"
>
  <div class="col gap1"
  >
    <lib-hero-content-card class="racksBG"
                           [titleBig]="bag.isCurrentRackEditable&&bag.isCurrentRackPropertyOfCurrentUser?'Rack Editing':'Rack Details'"
    >
      <lib-auto-content-loading-indicator [data$]="dataService.singleRackData$"
                                          [updateData$]="dataService.updateSingleRackData$"
      ></lib-auto-content-loading-indicator>
      <ng-container *ngIf="bag.data"
      >
        <div class="col gap1">
          <app-rack-composite [data]="(bag.data)"
          ></app-rack-composite>
          <lib-clean-card>
            <app-rack-editor
              [data]="(bag.data)"
            ></app-rack-editor>
          </lib-clean-card>
          
        
        </div>
      </ng-container>
    </lib-hero-content-card>
    <ng-container *ngIf="dataService.isCurrentRackPropertyOfCurrentUser$|async">
      <app-module-browser-root *ngIf="(dataService.isCurrentRackEditable$|async) else noBrowserOpen"
                               [viewConfig]="viewConfig"
      ></app-module-browser-root>
    </ng-container>
    <ng-container *ngIf="bag.data"
    >
      
      <lib-screen-wrapper maxSize="45rem"
                          style="padding-bottom: 2rem;"
      >
        <app-comments-root
        ></app-comments-root>
      </lib-screen-wrapper>
    </ng-container>
  </div>
</ng-container>
<!--template if no browser open-->
<ng-template #noBrowserOpen
>
  <div class="col gap4 center"
       style="
      font-size: 1.5em;
      padding: 3em;
"
  >
    <mat-label
    
    >
      Unlock to add modules or make changes
    </mat-label>
    
    <app-brand-primary-button (click$)="dataService.requestRackEditableStatusChange$.next()"
    >
      Unlock rack now
    </app-brand-primary-button>
  </div>

</ng-template>