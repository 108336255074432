<div class="col gap1"
>
  <lib-hero-content-card titleBig="Modules"
                         class="modulesBG"
                         description="Browse and discover modules, save your favourites and submit your own"
  >
    <div class="col"
    >
      <div class="rowwrap gap1 col-lt-SM"
      >
        <lib-mat-form-entity [control]="dataService.fields.name.control"
                             [type]="dataService.fields.name.type"
                             [label]="dataService.fields.name.label"
        ></lib-mat-form-entity>
        
        <lib-mat-form-entity [control]="dataService.fields.description.control"
                             [type]="dataService.fields.description.type"
                             [label]="dataService.fields.description.label"
        ></lib-mat-form-entity>
        
        <lib-mat-form-entity [control]="dataService.fields.manufacturers.control"
                             [type]="dataService.fields.manufacturers.type"
                             [options$]="dataService.fields.manufacturers.options$"
                             [label]="dataService.fields.manufacturers.label"
                             [disableVoidSelection]="false"
        ></lib-mat-form-entity>
        
        <lib-mat-form-entity [control]="dataService.fields.hpCondition.control"
                             [type]="dataService.fields.hpCondition.type"
                             [label]="dataService.fields.hpCondition.label"
                             [options$]="dataService.fields.hpCondition.options$"
                             [disableVoidSelection]="false"
        ></lib-mat-form-entity>
        <lib-mat-form-entity [control]="dataService.fields.hp.control"
                             [type]="dataService.fields.hp.type"
                             [label]="dataService.fields.hp.label"
        ></lib-mat-form-entity>
        
        <lib-mat-form-entity [control]="dataService.fields.standard.control"
                             [type]="dataService.fields.standard.type"
                             [options$]="dataService.fields.standard.options$"
                             [disableVoidSelection]="true"
                             [label]="dataService.fields.standard.label"
        ></lib-mat-form-entity>
        <lib-mat-form-entity [control]="dataService.fields.order.control"
                             [options$]="dataService.fields.order.options$"
                             [type]="dataService.fields.order.type"
                             [disableVoidSelection]="true"
                             [label]="dataService.fields.order.label"
        ></lib-mat-form-entity>
        
        <div>
          <button mat-icon-button
                  (click)="dataService.resetForm$.next()"
                  [matTooltip]="'Reset form'"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div style="margin-left:auto; "
        >
          <app-brand-primary-button routerLink="/modules/add"
                                    fxLayoutAlign="end"
          >Submit a NEW module
          </app-brand-primary-button>
        </div>
      
      
      </div>
      
      
      <app-module-list [data$]="dataService.modulesList$"
                       [viewConfig]="viewConfig"
                       [encloseVertically]="false"
      ></app-module-list>
      <mat-paginator [hidePageSize]="(dataService.serversideAdditionalData.itemsCount$|async)<20"
                     [pageSizeOptions]="[20,25,50,100]"
                     [length]="dataService.serversideAdditionalData.itemsCount$|async"
                     (page)="dataService.onPageEvent($event)"
                     showFirstLastButtons
                     style="margin-top:1rem;"
      ></mat-paginator>
      <lib-auto-update-loading-indicator [data$]="dataService.modulesList$"
                                         [updateData$]="dataService.updateModulesList$"
      ></lib-auto-update-loading-indicator>
    </div>
  </lib-hero-content-card>
</div>