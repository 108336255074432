<div class="container"
     fxFlex="100"
     fxLayout="column"
     [@fadeInOut]

>
  <div fxFlex="100"
       fxLayoutAlign="start center"
       class="text"
  >
    <mat-card-subtitle>
      <ng-container *ngIf="{data:dataService.infoText$|async} as bag">
        <ng-container *ngIf="bag.data&&bag.data.length>0; else noData">{{
            bag.data}}</ng-container>
      </ng-container>
    </mat-card-subtitle>
  </div>

</div>

<ng-template #noData>
  Additional info will appear here
</ng-template>
