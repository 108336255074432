(() => {
  "use strict";

  var e = {
      d: (o, r) => {
        for (var t in r) e.o(r, t) && !e.o(o, t) && Object.defineProperty(o, t, {
          enumerable: !0,
          get: r[t]
        });
      },
      o: (e, o) => Object.prototype.hasOwnProperty.call(e, o),
      r: e => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(e, "__esModule", {
          value: !0
        });
      }
    },
    o = {};
  e.r(o), e.d(o, {
    default: () => r
  });
  const r = "precision mediump float;\n\nvarying vec4 v_color;\n\nvoid main(void) {\n  gl_FragColor = v_color;\n}\n";
  module.exports = o;
})();