<a mat-raised-button
   [fxFlex]="innerFlex"
   [routerLink]="routerLink"
   [class]="'brand-button'+' '+theme"
   [disabled]="disabled||error"
   [ngClass]='{"disabled": disabled, "error": error }'
   [cdkTrapFocus]="autoFocus"
   (click)="disabled?doNothing():click$.emit()"
>
  <ng-content></ng-content>
</a>