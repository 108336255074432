<h1 mat-dialog-title>{{ title }}</h1>
<mat-dialog-content>
  <div class="col gap1"
  >
    <div *ngIf="description">
      {{ description }}
    </div>
    <lib-mat-form-entity [label]="data.label"
                         [type]="data.type"
                         [control]="data.control"
                         [errorProvider]="appState.globalUtils.errorProvider"
                         formFieldAppearanceType="outline"
                         style="margin-top: 2rem;"
    ></lib-mat-form-entity>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <!--      <app-brand-primary-button (click$)="dialogRef.close({result: undefined})"-->
    <!--                                theme="negative"-->
    <!--      >Cancel-->
    <!--      </app-brand-primary-button>-->
    <app-brand-primary-button (click$)="dialogRef.close({result: data.control.value})"
                              [disabled]="!(isValid$ | async)"
                              theme="positive"
                              style="align-self: flex-end;"
    >Confirm
    </app-brand-primary-button>
  
  </div>
</mat-dialog-actions>
  <!--  <mat-progress-bar mode="indeterminate"-->
  <!--                    [style.visibility]="data.control.valid ? 'hidden' : 'visible' "-->
  <!--  ></mat-progress-bar>-->

<!--</div>-->
<!--&lt;!&ndash;&ndash;&gt;-->