<ng-container *ngIf="{
patchEditingPanelOpenState:(dataService.patchEditingPanelOpenState$|async),
data:(dataService.singlePatchData$|async)
} as bag"
>
  <ng-container *ngIf="!bag.patchEditingPanelOpenState">
    <div class="col gap1"
    >
      <lib-hero-content-card titleBig="Patch details"
                             class="patchesBG"
      >
        <div class="col gap1"
        >
          <div>
            <ng-template [ngTemplateOutlet]="patch"></ng-template>
          </div>
          <lib-auto-content-loading-indicator [updateData$]="dataService.updateSinglePatchData$"
                                              [data$]="dataService.singlePatchData$"
          ></lib-auto-content-loading-indicator>
        </div>
      </lib-hero-content-card>
      
      <!--      <ng-container *ngIf="bag.data"-->
      <!--      >-->
      <!--        -->
      <!--        <lib-screen-wrapper maxSize="45rem"-->
      <!--                            style="padding-bottom: 2rem;"-->
      <!--        >-->
      <!--          <app-comments-root-->
      <!--          ></app-comments-root>-->
      <!--        </lib-screen-wrapper>-->
      <!--      </ng-container>-->
    </div>
  </ng-container>
  
  
  <ng-container *ngIf="bag.patchEditingPanelOpenState">
    <div class="col gap1"
    >
      <lib-hero-content-card titleBig="Patch editing"
                             class="patchesBG"
      >
        <div class="col gap1"
        >
          <div>
            <ng-template [ngTemplateOutlet]="patch"></ng-template>
          </div>
          <lib-auto-content-loading-indicator [updateData$]="dataService.updateSinglePatchData$"
                                              [data$]="dataService.singlePatchData$"
          ></lib-auto-content-loading-indicator>
        </div>
      </lib-hero-content-card>
      
      <lib-hero-content-card titleNormal="Connections Editor"
                             *ngIf="bag.data&&bag.patchEditingPanelOpenState"
      
      >
        <!--        <div class="col gap2">-->
        <!--          <div class="row gap1">-->
        <!--            <mat-slide-toggle [checked]="dataService.shouldShowPanelImages$|async"-->
        <!--                              (change)="dataService.shouldShowPanelImages$.next(!dataService.shouldShowPanelImages$.value)"-->
        <!--                              class="toggle">-->
        <!--              <div style="cursor: pointer;">-->
        <!--                Show panels images-->
        <!--              </div>-->
        <!--            </mat-slide-toggle>-->
        <!--          </div>-->
        <app-patch-editor [data]="(bag.data)"
        ></app-patch-editor>
        <!--        </div>-->
      </lib-hero-content-card>
    </div>
  </ng-container>
  
  <ng-template #patch>
    <ng-container *ngIf="bag.data"
    >
      <app-patch-composite [data]="(bag.data)"
                           [isEditing]="bag.patchEditingPanelOpenState"
                           [viewConfig]="viewConfig"
      ></app-patch-composite>
    </ng-container>
  </ng-template>
</ng-container>