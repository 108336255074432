<ng-container *ngIf="{
  user:(userManagerService.loggedUser$|async),
  isInCollection:(isInCollection$|async),
  singleModuleData:(dataService.singleModuleData$|async)
  } as bag"
>
  <div class="col parent"
  >
    <div class="col gap0"
    >
      <app-module-part-image [big]="viewConfig.bigPanelImage"
                             [data]="data"
                             class="panel"
      ></app-module-part-image>
      <app-module-part-name [data]="data"></app-module-part-name>
      <div class="row gap1 space-between"
      >
        <app-module-part-manufacturer *ngIf="!viewConfig.hideManufacturer"
                                      [data]="data"
        ></app-module-part-manufacturer>
        <app-module-part-hp *ngIf="!viewConfig.hideHP"
                            [data]="data"
        ></app-module-part-hp>
      </div>
      <app-timestamps-relative *ngIf="!viewConfig.hideDates"
                               [data]="data"
      ></app-timestamps-relative>
      <app-module-part-description *ngIf="!viewConfig.hideDescription"
                                   [data]="data"
                                   [viewConfig]="viewConfig"
      ></app-module-part-description>
    
    </div>
    
    <app-module-tags *ngIf="!viewConfig.hideTags"
                     [data]="data"
    ></app-module-tags>
    <ng-content></ng-content>
    <ng-container *ngIf="!viewConfig.hideButtons">
      <mat-divider [inset]="true"
                   style="margin-top:.5rem"
      ></mat-divider>
      <div class="row gap2 opacizeOnHover"
           fxLayoutGap.xs="0rem"
      >
        <div fxFlex="100"
             fxLayout="row"
             fxLayoutGap="1rem"
        >
          <ng-container *ngIf="!bag.isInCollection">
            <button (click)="dataService.addModuleToCollection$.next(data.id)"
                    *ngIf="(bag.singleModuleData)"
                    [disabled]="!bag.user||(bag.singleModuleData.manufacturer.id===10000)"
                    [title]="!bag.user ? 'Login to add to modules your collection':''"
                    mat-icon-button
                    matTooltip="Add to your collection"
            >
              <mat-icon>add</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="bag.isInCollection">
            <button (click)="dataService.removeModuleFromCollection$.next(data.id)"
                    *ngIf="(bag.singleModuleData)"
                    [disabled]="!bag.user||(bag.singleModuleData.manufacturer.id===10000)"
                    mat-icon-button
                    matTooltip="Remove from your collection"
            >
              <mat-icon>eject</mat-icon>
            </button>
          </ng-container>
          
          
          <button (click)="dataService.requestAddModuleToRack$.next(data)"
                  *ngIf="(bag.singleModuleData)"
                  [disabled]="(!bag.user)||(bag.singleModuleData.manufacturer.id===10000)"
                  [title]="!bag.user ? 'Login to add to modules your rack':''"
                  mat-icon-button
                  matTooltip="Add to rack"
          >
            <mat-icon>playlist_add</mat-icon>
          </button>
          
          
          <ng-container *ngIf="rackDataService.singleRackData$|async as rackData">
            <button (click)="rackDataService.addModuleToRack$.next(data)"
                    *ngIf="rackDataService.isCurrentRackEditable$|async"
                    [matTooltip]="'Add to '+rackData.name"
                    [title]="!bag.user ? 'Login to add to modules your rack':''"
                    mat-icon-button
            >
              <mat-icon>playlist_add</mat-icon>
            </button>
          </ng-container>
        
        </div>
        <div fxFlex="grow"></div>
        <div class="row gap1">
          <ng-container *ngIf="(bag.singleModuleData&&bag.user)">
            <ng-container *ngIf="false">
              <ng-container *ngIf="(dataService.moduleEditingPanelOpenState$|async)==false">
                <button
                  (click)="dataService.moduleEditingPanelOpenState$.next(!dataService.moduleEditingPanelOpenState$.value)"
                  [disabled]="(!bag.user)||bag.singleModuleData.isComplete||(bag.singleModuleData.manufacturer.id===10000)"
                  mat-icon-button
                  matTooltip="Edit module"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="(dataService.moduleEditingPanelOpenState$|async)==true">
                <button
                  (click)="dataService.moduleEditingPanelOpenState$.next(!dataService.moduleEditingPanelOpenState$.value)"
                  [disabled]="!bag.user"
                  mat-icon-button
                  matTooltip="Close editor"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="bag.singleModuleData">
            <a *ngIf="bag.singleModuleData.manualURL"
               [href]="bag.singleModuleData.manualURL"
               mat-icon-button
               matTooltip="Open manual"
               rel="noopener noreferrer"
               target="_blank"
            >
              <mat-icon>menu_book</mat-icon>
            </a>
            
            <a (click)="dataService.copyModuleNameAndManufacturer$.next()"
               mat-icon-button
               matTooltip="Copy module name to clipboard"
            >
              <mat-icon>content_copy</mat-icon>
            </a>
            
          </ng-container>
          
          
          <!--          <ng-container *ngIf="!bag.singleModuleData">-->
          <!--            <a mat-icon-button-->
          <!--               matTooltip="Show details"-->
          <!--               [routerLink]="['/modules/details',data.id]"-->
          <!--            >-->
          <!--              <mat-icon>info</mat-icon>-->
          <!--            </a>-->
          <!--  -->
          <!--          </ng-container>-->
        
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>