<lib-screen-wrapper maxSize="40rem">
  <div class="col gap1"
  >
    <lib-hero-content-card titleBig="Account management"
                           icon="manage_accounts"
                           class="userBG"
    >
      <div class="col gap2"
      >
  
        <ng-container *ngIf="{
          userProfile:(userManagementService.loggedUserFullProfile$|async),
          user:(userManagementService.loggedUser$|async)
          } as bag"
        >
          <div class="col gap1"
               *ngIf="bag.user&&bag.userProfile"
          >
            <app-brand-primary-button fxFlex="100"
                                      innerFlex="fill"
                                      style="padding-bottom:4rem"
                                      (click$)="userManagementService.logoff$()"
                                      theme="warning"
            >Logout
            </app-brand-primary-button>
            <div class="row gap1">
              <app-label-value-showcase label="Username"
                                        fxFlex="grow"
              >{{bag.userProfile.username}}</app-label-value-showcase>
              <app-brand-primary-button disabled="true"
                                        matTooltip="Sorry, this feature is not yet implemented, but will be soon, reach out to us via mail if you need help with this"
                                        theme="warning"
              >Change
              </app-brand-primary-button>
            </div>
            <app-label-value-showcase label="Email">{{bag.userProfile.email}}</app-label-value-showcase>
            <div class="row gap1">
              <app-label-value-showcase label="Password"
                                        fxFlex="grow"
              >********
              </app-label-value-showcase>
              <app-brand-primary-button disabled="true"
                                        matTooltip="Sorry, this feature is not yet implemented, but will be soon, reach out to us via mail if you need help with this"
                                        theme="warning"
              >Change
              </app-brand-primary-button>
            </div>
            <app-label-value-showcase label="Account created">{{bag.user.created_at|timeago}}</app-label-value-showcase>
            <app-label-value-showcase label="Account id (secret! send this to us when requesting help via mail)">{{bag.user.id}}</app-label-value-showcase>
          </div>
        </ng-container>
  
        <div class="row gap1">
          <app-brand-primary-button style="padding-top:4rem"
                                    disabled="true"
                                    matTooltip="Sorry, this feature is not yet implemented, but will be soon, reach out to us via mail if you need help with this"
                                    theme="warning"
          >Delete Account
          </app-brand-primary-button>
    
          <app-brand-primary-button style="padding-top:4rem"
                                    disabled="true"
                                    matTooltip="Sorry, this feature is not yet implemented, but will be soon, reach out to us via mail if you need help with this"
                                    theme="warning"
          >Delete data
          </app-brand-primary-button>
        </div>

      </div>
    </lib-hero-content-card>
    <app-empty-state></app-empty-state>
  
  </div>
  
  <ng-template #noData>
    <div fxFlex="100">
      <!--    <mat-card-subtitle fxLayoutAlign="center center">It looks like you haven't added any racks-->
      <!--                                                     to your collection yet.-->
      <!--                                                     Try adding some!-->
      <!--    </mat-card-subtitle>-->
    </div>
  </ng-template>
</lib-screen-wrapper>