<lib-mat-form-entity [control]="dataService.search.control"
                     [type]="dataService.search.type"
                     [label]="dataService.search.label"
></lib-mat-form-entity>
<lib-mat-form-entity *ngIf="false"
                     [control]="dataService.order.control"
                     [options$]="dataService.order.options$"
                     [type]="dataService.order.type"
                     [disableVoidSelection]="true"
                     [label]="dataService.order.label"
></lib-mat-form-entity>