"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createEdgeCompoundProgram = exports.AbstractEdgeProgram = void 0;
/**
 * Sigma.js WebGL Abstract Edge Program
 * =====================================
 *
 * @module
 */
var program_1 = require("./program");
/**
 * Edge Program class.
 *
 * @constructor
 */
var AbstractEdgeProgram = /** @class */function (_super) {
  __extends(AbstractEdgeProgram, _super);
  function AbstractEdgeProgram(gl, vertexShaderSource, fragmentShaderSource, points, attributes) {
    return _super.call(this, gl, vertexShaderSource, fragmentShaderSource, points, attributes) || this;
  }
  return AbstractEdgeProgram;
}(program_1.AbstractProgram);
exports.AbstractEdgeProgram = AbstractEdgeProgram;
function createEdgeCompoundProgram(programClasses) {
  return /** @class */function () {
    function EdgeCompoundProgram(gl, renderer) {
      this.programs = programClasses.map(function (ProgramClass) {
        return new ProgramClass(gl, renderer);
      });
    }
    EdgeCompoundProgram.prototype.bufferData = function () {
      this.programs.forEach(function (program) {
        return program.bufferData();
      });
    };
    EdgeCompoundProgram.prototype.allocate = function (capacity) {
      this.programs.forEach(function (program) {
        return program.allocate(capacity);
      });
    };
    EdgeCompoundProgram.prototype.bind = function () {
      // nothing todo, it's already done in each program constructor
    };
    EdgeCompoundProgram.prototype.computeIndices = function () {
      this.programs.forEach(function (program) {
        return program.computeIndices();
      });
    };
    EdgeCompoundProgram.prototype.render = function (params) {
      this.programs.forEach(function (program) {
        program.bind();
        program.bufferData();
        program.render(params);
      });
    };
    EdgeCompoundProgram.prototype.process = function (sourceData, targetData, data, hidden, offset) {
      this.programs.forEach(function (program) {
        return program.process(sourceData, targetData, data, hidden, offset);
      });
    };
    return EdgeCompoundProgram;
  }();
}
exports.createEdgeCompoundProgram = createEdgeCompoundProgram;