<div class="col gap1"
>
  <lib-screen-wrapper maxSize="36rem">
    <lib-clean-card>
      <app-rack-minimal [data]="data">
      </app-rack-minimal>
    </lib-clean-card>
  </lib-screen-wrapper>
  <app-rack-details [data]="data"></app-rack-details>
</div>
