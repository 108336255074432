<div class="col gap1"
>
  <lib-hero-content-card titleNormal="Patches"
                         description="So many patches, so little time. Patches you've created and those you're currently working on"
                         class="patchesBG"
                         icon="settings_input_composite"
                         id="patches"
  >
    <div class="col gap1"
    >
      <div class="row gap1 auto-left"
      >
        <app-brand-primary-button
          (click$)="dataService.addPatch$.next()"
          fxLayoutAlign="end"
        >Create patch
        </app-brand-primary-button>
      </div>
      <mat-divider inset="true"></mat-divider>
      <ng-container *ngIf="(dataService.patchesData$|async) as data">
        <app-patch-list fxFlex="grow"
                        [data$]="dataService.patchesData$"
                        [showSearch]="true"
                        *ngIf="data.length>0 else voidData"
        ></app-patch-list>
      </ng-container>
      <lib-auto-update-loading-indicator [data$]="dataService.patchesData$"
                                         [updateData$]="dataService.updatePatchesData$"
      ></lib-auto-update-loading-indicator>

    </div>
  </lib-hero-content-card>
</div>

<ng-template #voidData>
  <div fxFlex="100">
    <mat-card-subtitle fxLayoutAlign="center center">It looks like you haven't created any patches
                                                     yet. Try adding some!
    </mat-card-subtitle>
  </div>
</ng-template>