<div class="col gap1 parent"
>
  <app-module-cvs [data]="data"></app-module-cvs>
  <ng-container *ngIf="switches.length>0">
    <mat-card-subtitle>{{data.switches.length}} Switches:</mat-card-subtitle>
    <div fxLayout="row wrap"
         fxLayoutGap="1rem"
    >
      <!--      <mat-chip-list>-->
      <!--        <ng-container *ngFor="let item of switches">-->
      <!--          &lt;!&ndash;                            <mat-chip>{{item.name}}</mat-chip>&ndash;&gt;-->
      <!--        </ng-container>-->
      <!--      </mat-chip-list>-->
    </div>
  </ng-container>
  <mat-accordion *ngIf="(data.panels.length>1)&&(!viewConfig.hidePanelsOptions)">
    <mat-expansion-panel hideToggle="true"
                         style="padding-top: 1rem"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Available in {{data.panels.length}} versions
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxFlex="auto"
           fxLayout="row wrap"
           fxLayoutGap=".5rem grid"
      >
        <ng-container *ngFor="let item of data.panels; let i = index">
          <div fxFlex="auto"
               fxLayout="column"
               fxLayoutGap="1rem"
          >
            <div fxFlex="auto">
              <img *ngIf="item.filename"
                   alt="{{item.filename}}"
                   class="image-transition"
                   loading="lazy"
                   [ngStyle]="{maxHeight:((data.standard|getModuleHeightForStandard)+'rem')}"
                   [matTooltip]="item.filename"
                   [src]="'https://sozmatmywjpstwidzlss.supabase.co/storage/v1/object/public/module-panels/'+item.filename"
              >
            </div>
            <!--            <mat-chip>-->
            <!--              {{item.filename}}-->
            <!--            </mat-chip>-->
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  
  
  <p fxLayoutAlign="end"
     style="font-size: 1rem"
     *ngIf="data.manufacturer.id==10000"
  >
    Abstract module, used for educational patches.
  </p>


</div>