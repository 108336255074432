(() => {
  "use strict";

  var e = {
      d: (n, o) => {
        for (var t in o) e.o(o, t) && !e.o(n, t) && Object.defineProperty(n, t, {
          enumerable: !0,
          get: o[t]
        });
      },
      o: (e, n) => Object.prototype.hasOwnProperty.call(e, n),
      r: e => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(e, "__esModule", {
          value: !0
        });
      }
    },
    n = {};
  e.r(n), e.d(n, {
    default: () => o
  });
  const o = "precision mediump float;\n\nvarying vec4 v_color;\nvarying float v_border;\n\nconst float radius = 0.5;\nconst vec4 transparent = vec4(0.0, 0.0, 0.0, 0.0);\n\nvoid main(void) {\n  vec2 m = gl_PointCoord - vec2(0.5, 0.5);\n  float dist = radius - length(m);\n\n  float t = 0.0;\n  if (dist > v_border)\n    t = 1.0;\n  else if (dist > 0.0)\n    t = dist / v_border;\n\n  gl_FragColor = mix(transparent, v_color, t);\n}\n";
  module.exports = n;
})();