<div class="col gap1"
>
  <lib-hero-content-card titleBig="Patches"
                         class="patchesBG"
                         description="Browse and discover patches, get inspired and exchange ideas"
  >
    <div class="col gap"
    >
      <div fxLayout="row"
           fxLayout.lt-sm="column"
           fxLayoutGap="1rem"
      >
        <lib-mat-form-entity [control]="dataService.fields.search.control"
                             [type]="dataService.formTypes.TEXT"
                             [label]="dataService.fields.search.label"
        ></lib-mat-form-entity>
        
        <lib-mat-form-entity [control]="dataService.fields.order.control"
                             [options$]="dataService.fields.order.options$"
                             [type]="dataService.formTypes.SELECT"
                             [disableVoidSelection]="true"
                             [label]="dataService.fields.order.label"
        ></lib-mat-form-entity>
  
        <div fxFlex="grow"></div>
      </div>
      
      <app-patch-list [data$]="dataService.patchesList$"
                      [viewConfig]="viewConfig"
      ></app-patch-list>
      <mat-paginator [hidePageSize]="(dataService.serversideAdditionalData.itemsCount$|async)<20"
                     [pageSizeOptions]="[20,25,50,100]"
                     [length]="dataService.serversideAdditionalData.itemsCount$|async"
                     (page)="dataService.onPageEvent($event)"
                     showFirstLastButtons
                     style="margin-top:1rem;"
      ></mat-paginator>
      <lib-auto-content-loading-indicator [data$]="dataService.patchesList$"
                                          [updateData$]="dataService.updatePatchesList$"
      ></lib-auto-content-loading-indicator>
    </div>
    <!--  <pre>{{(dataService.data$|async)|json}}</pre>-->
  </lib-hero-content-card>
</div>