<lib-clean-card>
  <div class="row gap1"
  >
    <ng-container *ngIf="name; else noUser">
      <!--            <div fxFlex='2rem'-->
      <!--                 fxLayoutAlign='top'-->
      <!--                 [ngStyle]='{"background-image": "url("+ backgroundImagePath + ")"}'-->
      <!--                 class='bg'-->
      <!--            >-->
      <!--                -->
      <!--            </div>-->
  
      <div fxLayout="column "
           fxFlex="grow"
           fxLayoutGap="1rem"
  
      >
        <div fxLayout="row"
             fxFlex="100"
             fxLayoutGap="1rem"

        >
          <!--          <div fxFlex="100"-->
          <!--               style="font-size: 2rem; margin-right: 1rem"-->
          <!--          >👤-->
          <!--          </div>-->
          <div fxFlex="grow"
               class="name"
               fxLayoutAlign="start"
          >
            {{ name }}
          </div>
        </div>
        <div fxFlex="100"
             fxLayoutAlign="end"
             *ngIf="!hideLogoff"
        >
          <button mat-stroked-button
                  (click)="logoff$.emit()"
          >Logoff
          </button>
        </div>
      </div>
    </ng-container>
  
    <ng-template #noUser>
      <div class="col gap1"
      >
        <button mat-flat-button
                color="accent"
                (click)="login$.emit()"
        >Login
        </button>
        <button mat-flat-button
                color="primary"
                (click)="signup$.emit()"
        >Sign up
        </button>

      </div>
    </ng-template>
  </div>
</lib-clean-card>
