<div class="col gap1"
>
  
  <h1 mat-dialog-title>Add module to rack</h1>
  <div>
    <mat-dialog-content>
      <!--      <app-rack-visual-model></app-rack-visual-model>-->
      <div fxFlex="100"
           fxLayout="column wrap"
           fxLayout.xs="column"
           fxLayoutGap="1rem"
      >
        
        <lib-mat-form-entity fxFlex="100"
                             [type]="fields.rack.type"
                             [control]="fields.rack.control"
                             [label]="fields.rack.label"
                             [disableVoidSelection]="true"
                             [options$]="fields.rack.options$"
                             [disableClearButton]="false"
                             [disabled]="(userAreaDataService.rackData$|async)===undefined"
        >
        </lib-mat-form-entity>
      
      </div>
    
    </mat-dialog-content>
  </div>
</div>
<mat-dialog-actions style="border-top: 0.5rem solid rgba(13,112,35,0.4)">
  <div fxFlex="100"
       fxLayout="row"
       fxLayoutGap="2rem"
       fxLayoutGap.xs="0rem"
  >
    <div fxFlex="grow"></div>
    <app-brand-primary-button
        [error]="fields.rack.control.invalid"
        theme="positive"
        (click$)="saveRackedModule$.next()"
    >
      <!--      <mat-icon>save</mat-icon>-->
      Confirm
    </app-brand-primary-button>
  </div>

</mat-dialog-actions>