<div class="rowwrap gap2">
  <app-brand-primary-button (click$)="add$.next({
          name: '',
          id: 0,
          isApproved: false
        })"
  >Add Blank
  </app-brand-primary-button>
  <app-brand-primary-button (click$)="add$.next({
          name: '',
          id: 0,
          min: 0,
          max: 5,
          isApproved: false
        })"
  >Add 0→5V
  </app-brand-primary-button>
  <app-brand-primary-button (click$)="add$.next({
          name: '',
          id: 0,
          min: 0,
          max: 8,
          isApproved: false
        })"
  >Add 0→8V
  </app-brand-primary-button>
  <app-brand-primary-button (click$)="add$.next({
          name: '',
          id: 0,
          min: -5,
          max: 5,
          isApproved: false
        })"
  >Add -5→5V
  </app-brand-primary-button>
  <app-brand-primary-button (click$)="add$.next({
          name: '',
          id: 0,
          min: -10,
          max: 10,
          isApproved: false
        })"
  >Add -10→10V
  </app-brand-primary-button>
  <app-brand-primary-button (click$)="add$.next({
          name: '',
          id: 0,
          min: -12,
          max: 12,
          isApproved: false
        })"
  >Add -12→12V
  </app-brand-primary-button>
</div>