<div class="row gap1">
  <!-- Optional Icon -->
  <ng-container *ngIf="icon">
    <div class="icon">
      <mat-icon>{{ icon }}</mat-icon>
    </div>
  </ng-container>
  <div [ngClass]="{'column': valueBelow, 'row': !valueBelow, 'space-between': pushToEnd}"
>
    <div class="label">
      {{ label }}
    </div>
    <div class="value-content row">
      <div [ngClass]="{'end': pushToEnd, 'value': bigText}">
        <div [ngClass]="{'monospace': monospace}">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
</div>