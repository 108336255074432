<lib-screen-wrapper maxSize="32rem"
>
  <div class="col gap1">
    <lib-hero-content-card titleBig="Who are you again?"
                           style="padding-bottom: 32rem;"
    >
      <div class="col gap1"
      >
        <app-login-email fxFlex="100"></app-login-email>
        <mat-card-subtitle fxLayoutAlign="center"
                           routerLink="/auth/signup"
                           style="cursor: pointer"
        >Sign up instead?
        </mat-card-subtitle>
      </div>
    
    </lib-hero-content-card>
  </div>

</lib-screen-wrapper>
