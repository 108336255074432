"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNodeCompoundProgram = exports.AbstractNodeProgram = void 0;
/**
 * Sigma.js WebGL Abstract Node Program
 * =====================================
 *
 * @module
 */
var program_1 = require("./program");
/**
 * Node Program class.
 *
 * @constructor
 */
var AbstractNodeProgram = /** @class */function (_super) {
  __extends(AbstractNodeProgram, _super);
  function AbstractNodeProgram(gl, vertexShaderSource, fragmentShaderSource, points, attributes) {
    var _this = _super.call(this, gl, vertexShaderSource, fragmentShaderSource, points, attributes) || this;
    // Locations
    _this.positionLocation = gl.getAttribLocation(_this.program, "a_position");
    _this.sizeLocation = gl.getAttribLocation(_this.program, "a_size");
    _this.colorLocation = gl.getAttribLocation(_this.program, "a_color");
    // Uniform Location
    var matrixLocation = gl.getUniformLocation(_this.program, "u_matrix");
    if (matrixLocation === null) throw new Error("AbstractNodeProgram: error while getting matrixLocation");
    _this.matrixLocation = matrixLocation;
    var ratioLocation = gl.getUniformLocation(_this.program, "u_ratio");
    if (ratioLocation === null) throw new Error("AbstractNodeProgram: error while getting ratioLocation");
    _this.ratioLocation = ratioLocation;
    var scaleLocation = gl.getUniformLocation(_this.program, "u_scale");
    if (scaleLocation === null) throw new Error("AbstractNodeProgram: error while getting scaleLocation");
    _this.scaleLocation = scaleLocation;
    return _this;
  }
  AbstractNodeProgram.prototype.bind = function () {
    var gl = this.gl;
    gl.enableVertexAttribArray(this.positionLocation);
    gl.enableVertexAttribArray(this.sizeLocation);
    gl.enableVertexAttribArray(this.colorLocation);
    gl.vertexAttribPointer(this.positionLocation, 2, gl.FLOAT, false, this.attributes * Float32Array.BYTES_PER_ELEMENT, 0);
    gl.vertexAttribPointer(this.sizeLocation, 1, gl.FLOAT, false, this.attributes * Float32Array.BYTES_PER_ELEMENT, 8);
    gl.vertexAttribPointer(this.colorLocation, 4, gl.UNSIGNED_BYTE, true, this.attributes * Float32Array.BYTES_PER_ELEMENT, 12);
  };
  return AbstractNodeProgram;
}(program_1.AbstractProgram);
exports.AbstractNodeProgram = AbstractNodeProgram;
/**
 * Helper function combining two or more programs into a single compound one.
 * Note that this is more a quick & easy way to combine program than a really
 * performant option. More performant programs can be written entirely.
 *
 * @param  {array}    programClasses - Program classes to combine.
 * @return {function}
 */
function createNodeCompoundProgram(programClasses) {
  return /** @class */function () {
    function NodeCompoundProgram(gl, renderer) {
      this.programs = programClasses.map(function (ProgramClass) {
        return new ProgramClass(gl, renderer);
      });
    }
    NodeCompoundProgram.prototype.bufferData = function () {
      this.programs.forEach(function (program) {
        return program.bufferData();
      });
    };
    NodeCompoundProgram.prototype.allocate = function (capacity) {
      this.programs.forEach(function (program) {
        return program.allocate(capacity);
      });
    };
    NodeCompoundProgram.prototype.bind = function () {
      // nothing todo, it's already done in each program constructor
    };
    NodeCompoundProgram.prototype.render = function (params) {
      this.programs.forEach(function (program) {
        program.bind();
        program.bufferData();
        program.render(params);
      });
    };
    NodeCompoundProgram.prototype.process = function (data, hidden, offset) {
      this.programs.forEach(function (program) {
        return program.process(data, hidden, offset);
      });
    };
    return NodeCompoundProgram;
  }();
}
exports.createNodeCompoundProgram = createNodeCompoundProgram;