<lib-screen-wrapper>
  <div class="col gap1"
  >
    <div class="col gap2 homeBG"
         style="padding: 4rem 2rem 8rem;"
    >
      <h1 [@titleEnter]
      > PATCHER.XYZ</h1>
      <h1 [@subtitleEnter]
      > the modern way to manage everything modular</h1>
      <mat-divider inset="true"></mat-divider>
      <div class="col gap1" style="font-size: 1.8rem;">
        <div class="col gap1">
          <b>Discover the world of modular.</b>
          <p>Explore a vast library of modules, discover new favorites, and build your dream rack with ease.</p>
          <div class="col gap1">
            <b>Craft stunning patches, share them effortlessly.</b>
            <p>Record and share complex patch notes with a click. Impress collaborators, document your creative
              journey,
              and never forget a sonic masterpiece.</p>
          </div>
          <div class="col gap1">
            <b>Effortless rack management at your fingertips.</b>
            <p>Design, configure, and
              optimize your modular system with lightning speed. Visualize your dream setup, manage all your racks
              effortlessly, and unlock your next sonic adventure.</p>
          </div>
          
          <div class="col gap1">
            <p>Create an account to get started.</p>
            <div class="row gap3"
            >
              <app-brand-primary-button routerLink="/auth/signup">Create an account
              </app-brand-primary-button>
              <app-brand-primary-button routerLink="/auth/login">Login
              </app-brand-primary-button>
            </div>
          </div>
        </div>
      </div>
      <mat-divider inset="true"></mat-divider>
    
    </div>
    <ng-container>
      
      <div class="col gap2 belowContainer"
      >
        <div class="col gap1"
        >
          <lib-hero-header class="gradientCard"
                           description="Record and share complex patch notes with a click. Impress your friends, document your creative journey, and never forget a sonic masterpiece."
                           title="Create, share, and explore patches"
          >
            <lib-screen-wrapper maxSize="75rem">
              <lib-device-frame-wrapper>
                <app-patch-browser-patch-detail [ignoreSeo]="true"
                                                [viewConfig]="patchViewConfig"
                                                class="embedded"
                ></app-patch-browser-patch-detail>
              </lib-device-frame-wrapper>
            </lib-screen-wrapper>
          </lib-hero-header>
          <mat-divider inset="true"
          ></mat-divider>
          
          <lib-hero-header class="gradientCard"
                           description="Explore a vast library of modules, discover new favorites, and track your collection with ease."
                           title="Discover new modules"
          >
            <lib-screen-wrapper maxSize="75rem">
              <lib-device-frame-wrapper>
                <app-module-browser-detail [ignoreSeo]="true"
                                           [viewConfig]="moduleViewConfig"
                                           class="embedded"
                >
                
                </app-module-browser-detail>
              </lib-device-frame-wrapper>
            </lib-screen-wrapper>
          </lib-hero-header>
          
          <mat-divider inset="true"></mat-divider>
          
          <lib-hero-header class="gradientCard"
                           description="Move modules freely, visualize your dream setup, and manage all your racks effortlessly."
                           title="Manage racks with unprecedented ease"
          >
            <lib-screen-wrapper maxSize="75rem">
              <lib-device-frame-wrapper>
                <app-rack-browser-rack-detail [ignoreSeo]="true"
                                              [viewConfig]="rackViewConfig"
                                              class="embedded"
                ></app-rack-browser-rack-detail>
              </lib-device-frame-wrapper>
            </lib-screen-wrapper>
          </lib-hero-header>
          
          
          <lib-hero-header class="gradientCard"
                           description="Create an account to get started."
                           title="Use it! For free!"
          >
            <lib-screen-wrapper>
              <div class="row gap3"
              >
                <app-brand-primary-button routerLink="/auth/signup">Create an account
                </app-brand-primary-button>
                <app-brand-primary-button routerLink="/auth/login">Login
                </app-brand-primary-button>
              </div>
            </lib-screen-wrapper>
          </lib-hero-header>
        </div>
      </div>
    </ng-container>
  
  
  </div>
  <!--  <app-lottie-container fxLayoutAlign="center center"-->
  <!--                        [options]="{path: '/assets/json/animation.json'}"-->
  <!--  ></app-lottie-container>-->
</lib-screen-wrapper>