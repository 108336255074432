<lib-clean-card>
  <div class="col gap1"
  >
    <div class="col gap1">
      <div class="col gap1"
      >
        <div class="auto col gap1"
        >
          <div fxLayout="row"
               fxLayoutGap="1rem"
               fxLayoutAlign="space-between"
          >
            <mat-card-title>INs</mat-card-title>
            <mat-card-subtitle>leave fields blank if unknown</mat-card-subtitle>
          </div>
          <app-module-editor-adder-line (add$)="addIN$.next($event)"></app-module-editor-adder-line>
          
          <div class="col gap1 leftBorderS"
               style="margin-top: 1rem;"
          >
            <ng-container *ngFor="let item of INs$|async; let i = index">
              <app-module-editor-cv-form-line [item]="item"
                                              (removeRequest$)="removeIN$.next(i)"
              ></app-module-editor-cv-form-line>
          
            </ng-container>
        
          </div>
        </div>
        <div class="auto col gap1"
        >
          <div fxLayout="row"
               fxLayoutGap="1rem"
               fxLayoutAlign="space-between"
          >
            <mat-card-title>OUTs</mat-card-title>
            <mat-card-subtitle>leave fields blank if unknown</mat-card-subtitle>
          </div>
          <app-module-editor-adder-line (add$)="addOUT$.next($event)"></app-module-editor-adder-line>
          <div class="col gap1 leftBorderS"
               style="margin-top: 1rem;"
          >
            <ng-container *ngFor="let item of OUTs$|async; let i = index">
              <app-module-editor-cv-form-line [item]="item"
                                              (removeRequest$)="removeOUT$.next(i)"
              ></app-module-editor-cv-form-line>
            </ng-container>
          </div>
        
        
        </div>
      </div>
    
    
      <!--    <app-brand-primary-button>Add SWITCH</app-brand-primary-button>-->
      <!--    <ng-container *ngIf="-->
      <!--  ((INs$|async)|onlyNotSavedFormCVsLength)!== 0 ||-->
      <!--  ((OUTs$|async)|onlyNotSavedFormCVsLength) !== 0-->
      <!--"-->
      <!--    >-->
      <div fxLayout="row"
           fxLayout.lt-md="column"
           fxLayoutGap="1rem"
      >
        <mat-card-subtitle fxFlex="100"
                           fxLayoutAlign="end center"
        >Any changes you make will affect what all other users see
        </mat-card-subtitle>
      </div>
      <mat-divider inset="true"></mat-divider>
      <lib-auto-update-loading-indicator [data$]="dataService.singleModuleData$"
                                         [updateData$]="save$"
                                         loadingLabel="Saving data"
      >
        <div class="row gap2">
          
          <app-advice-tooltip fxFlex="basis"
          >Please update data only if it is incorrect or missing.
            If you are unsure, leave it as is.
          </app-advice-tooltip>
          <app-brand-primary-button (click$)="save$.next()"
                                    theme="positive"
                                    fxLayoutAlign="end"
                                    [disabled]="
                                (!(formGroupA|formValid))||
                                (!(formGroupB|formValid))
  "
          >
            <!--          <mat-icon>save</mat-icon>-->
            Save INs and OUTs
          </app-brand-primary-button>
        </div>
      </lib-auto-update-loading-indicator>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="col gap1"
    >
      <div fxLayout="row"
           fxLayoutGap="1rem"
           fxLayoutAlign="space-between"
      >
        <mat-card-title>Panel</mat-card-title>
        <!--        <mat-card-subtitle>leave fields blank if unknown</mat-card-subtitle>-->
      </div>
  
      <div class="row gap1"
           fxLayoutAlign="center center"
      >
        <lib-file-drag-host fxFlex="40%"
                            [isImageOnlyMode]="true"
                            [multipleFilesMode]="false"
                            acceptedFileType="image/jpeg,image/jpg"
        ></lib-file-drag-host>
        
        <div class="col gap1">
          <lib-mat-form-entity
            [dataPack]="panelType"
            [disableVoidSelection]="true"
          ></lib-mat-form-entity>
          <lib-mat-form-entity
            [dataPack]="panelDescription"
          ></lib-mat-form-entity>
        </div>
      </div>
      <mat-divider inset="true"></mat-divider>
      <lib-auto-update-loading-indicator [data$]="dataService.singleModuleData$"
                                         [updateData$]="savePanels$"
                                         loadingLabel="Saving data"
      >
        <app-brand-primary-button (click$)="savePanels$.next()"
                                  [disabled]="!((formGroupPanel|formValid)||((fileDragHostService.files$|async).length>0))"
                                  theme="positive"
                                  fxLayoutAlign="end"
        >
          <!--          <mat-icon>save</mat-icon>-->
          Add panel
        </app-brand-primary-button>
      </lib-auto-update-loading-indicator>
    </div>
  </div>

</lib-clean-card>