<ng-container *ngIf="{
  editorConnections:(dataService.editorConnections$|async),
  selectedForConnection:(dataService.selectedForConnection$|async)
  } as bag"
>
  
  
  <lib-auto-content-loading-indicator *ngIf="bag.editorConnections==null;else data"
                                      [skipFirstData]="true"
                                      [loadingLines]="20"
  >
  </lib-auto-content-loading-indicator>
  <ng-template #data>
    <div class="col">
      <lib-hero-content-card *ngIf="bag.editorConnections.length>0"
                             [titleNormal]="'Patch connections ('+bag.editorConnections.length+')'"
                             class="connections"
                             description="The connections of the patch you are editing, shown in reverse order"
                             [showHelpButton]="true"
      >
        
        <app-patch-connections-list [editorConnections]="bag.editorConnections"
                                    [isEditing]="true"
                                    [reverseOrder]="true"
        ></app-patch-connections-list>
      
      </lib-hero-content-card>
      
      <div class="row gap1"
      >
        <div class="gap1 col" style="min-width: 26rem"
        >
          <lib-auto-update-loading-indicator [data$]="dataService.singlePatchData$"
                                             [updateData$]="dataService.savePatchEditing$"
                                             loadingLabel="Saving data"
          >
            <div class="row gap1"
            >
              <mat-card-subtitle fxFlex="grow"
                                 fxLayoutAlign="end center"
              > You're editing your patch. Remember to save
                after
                you're done!
              </mat-card-subtitle>
              <div style="margin-top: .5rem">
                <app-brand-primary-button (click$)="dataService.savePatchEditing$.next()"
                                          theme="positive"
                                          fxLayoutAlign="end"
                                          [disabled]="(!(dataService.formData.name.control|formValid))||(!(dataService.formData.description.control|formValid))"
                >
                  <div class="row gap">
                    <!--                    <mat-icon>save</mat-icon>-->
                    <div>
                      Save patch
                    </div>
                  </div>
                </app-brand-primary-button>
              </div>
            
            
            </div>
          </lib-auto-update-loading-indicator>
          
          <lib-hero-content-card class="selection"
                                 titleNormal="Your selection"
                                 description="The connection you want to create"
                                 style="min-width: 26rem; max-width: 26rem"
                                 [showHelpButton]="true"
          >
            <lib-clean-card
              *ngIf="bag.selectedForConnection.a&&bag.selectedForConnection.b; else selectedForConnectionVoidState">
              <app-patch-connection-minimal [data]="{
                                                            a:bag.selectedForConnection.a.cv,
                                                            b:bag.selectedForConnection.b.cv,
                                                            patch:dataService.singlePatchData$|async
                                                            }"
                                            [isEditing]="true"
                                            [isCreator]="true"
                                            (remove$)="dataService.resetSelectedForConnection$.next()"
                                            (create$)="dataService.confirmSelectedConnection$.next()"
              ></app-patch-connection-minimal>
            </lib-clean-card>
            <ng-template #selectedForConnectionVoidState>
              <div class="animate-fadein-long">
                <app-advice-tooltip>Click on the ins and outs to create connections
                </app-advice-tooltip>
              </div>
              <div style="height:9rem;width:auto"
                   fxLayoutAlign="center center"
              >The connection will appear here
              </div>
            </ng-template>
          </lib-hero-content-card>
        
        </div>
        <app-user-modules class="modulesBG"
                          [encloseVertically]="false"
                          [userModulesComponentViewConfig]="userModulesComponentViewConfig"
                          [modulesViewConfig]="modulesViewConfig"
        ></app-user-modules>
      </div>
    </div>
  
  </ng-template>

</ng-container>