<div class="rowwrap gap1"
     style="display: grid; grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr)); gap: 1rem; margin-left: auto; margin-right: auto;">
  <div *ngFor="let item of data" style="display: flex; flex-direction: column; align-items: flex-start;">
    <div style="display: flex; align-items: center; gap: 0.5rem; min-height: 3rem; height: 3rem;">
      <mat-icon *ngIf="item.icon" style="flex-shrink: 0; align-self: center;">{{ item.icon }}</mat-icon>
      <mat-card-title
        style="flex-grow: 1; margin: 0; min-height: 3rem; line-height: 1.5rem; display: flex; align-items: center; justify-content: flex-start; height: 100%;">
        {{ item.question }}
      </mat-card-title>
    </div>
    <p>{{ item.answer }}</p>
    
    <ng-container *ngIf="item.link">
      <a [href]="item.link" target="_blank" style=" text-decoration: none;">
        <button mat-button color="primary">Open link</button>
      </a>
    </ng-container>
  </div>
</div>
