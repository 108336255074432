<lib-screen-wrapper maxSize="32rem"
                    style="margin-bottom:8rem"
>
  <div class="gradientCard col gap1"
  >
    <lib-hero-content-card titleBig="We haven't been introduced yet"
                           style="padding-bottom: 32rem;"
    >
      <div class="col gap1"
      >
        <app-signup-email fxFlex="100"></app-signup-email>
        
        
        <app-brand-primary-button *ngIf="false"
                                  style="padding-top: 1rem"
                                  innerFlex="auto"
                                  theme="positive"
                                  (click$)="dataService.googleSignClick$.next()"
        >Google Sign up
        </app-brand-primary-button>
        
        <mat-card-subtitle fxLayoutAlign="center"
                           routerLink="/auth/login"
                           style="cursor: pointer"
        >Login instead?
        </mat-card-subtitle>
      </div>
    </lib-hero-content-card>
  
  </div>
</lib-screen-wrapper>