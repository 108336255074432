"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function drawLabel(context, data, settings) {
  if (!data.label) return;
  var size = settings.labelSize,
    font = settings.labelFont,
    weight = settings.labelWeight,
    color = settings.labelColor.attribute ? data[settings.labelColor.attribute] || settings.labelColor.color || "#000" : settings.labelColor.color;
  context.fillStyle = color;
  context.font = "".concat(weight, " ").concat(size, "px ").concat(font);
  context.fillText(data.label, data.x + data.size + 3, data.y + size / 3);
}
exports.default = drawLabel;