<mat-toolbar color="primary"
             class="toolbar sticky"
>
  <lib-screen-wrapper fxFlex="grow">
    <div class="row gap1"
    >
      <a mat-button
         routerLink="/home"
         class="title "
         fxLayoutAlign="center center"
         [fxHide.xs]="true"
      >
        {{service.state.title|async}}
      </a>
      <!--      <app-brand-logo></app-brand-logo>-->
      <div fxFlex="grow"
           [fxHide.xs]="true"
      ></div>
      <div class="row gap1 buttons"
           fxLayoutGap.xs=".25rem"
      >
        <app-route-clickable-link-list [data$]="homeLinks$"></app-route-clickable-link-list>
        <mat-divider vertical="true"></mat-divider>
        <app-route-clickable-link-list [data$]="mainLinks$"></app-route-clickable-link-list>
      </div>
      <div fxFlex="grow"></div>
      <app-route-clickable-link-list [data$]="(userService.loggedUser$|async)?linksUser$:linksA$"></app-route-clickable-link-list>
    </div>
  </lib-screen-wrapper>
</mat-toolbar>