<div class="rowwrap gap1"
     *ngIf="!!item"
>
  <div>
    <button mat-icon-button
            [disabled]="!(item.id==0)"
            (click)="removeRequest$.next()"
    >
      <ng-container *ngIf="item.isApproved!==undefined&&item.isApproved; else deleteIcon"
      >
        <mat-icon>check</mat-icon>
      </ng-container>
    </button>
  </div>
  <lib-mat-form-entity [type]="types.TEXT"
                       [control]="item.name"
                       label="name"
  ></lib-mat-form-entity>
  <lib-mat-form-entity [type]="types.NUMBER"
                       [control]="item.a"
                       label="Minimum Voltage (V)"
  ></lib-mat-form-entity>
  <lib-mat-form-entity [type]="types.NUMBER"
                       [control]="item.b"
                       label="Maximum Voltage (V)"
  ></lib-mat-form-entity>
  <mat-divider inset="true"
               fxHide="true"
               fxShow.lt-md="true"
  ></mat-divider>
</div>

<ng-template #deleteIcon>
  <mat-icon>
    delete
  </mat-icon>

</ng-template>