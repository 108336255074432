<div class="col"
>
  <lib-mat-form-entity [dataPack]="dataService.fields.username"
                       hint="Visible by other users"
                       formFieldAppearanceType="fill"
  >
  </lib-mat-form-entity>
  <lib-mat-form-entity [type]="dataService.fields.email.type"
                       [control]="dataService.fields.email.control"
                       [label]="dataService.fields.email.label"
                       hint="NOT visible by other users"
                       formFieldAppearanceType="fill"
  >
  </lib-mat-form-entity>
  
  <lib-mat-form-entity [type]="dataService.fields.password.type"
                       [control]="dataService.fields.password.control"
                       [label]="dataService.fields.password.label"
                       formFieldAppearanceType="fill"
  >
  </lib-mat-form-entity>
  <lib-mat-form-entity [type]="dataService.fields.passwordAgain.type"
                       [control]="dataService.fields.passwordAgain.control"
                       [label]="dataService.fields.passwordAgain.label"
                       formFieldAppearanceType="fill"
  >
  </lib-mat-form-entity>
  
  <mat-error *ngIf="dataService.fields.password.control.value!=''&&dataService.fields.passwordAgain.control.value.length>0&&
dataService.fields.password.control.value!=dataService.fields.passwordAgain.control.value
"
  >Repeated password should be the same as original password
  </mat-error>
  
  <app-brand-primary-button style="padding-top: 1rem"
                            innerFlex="auto"
                            theme="positive"
                            [error]="(dataService.fields.username.control.invalid||dataService.fields.email.control.invalid||dataService.fields.password.control.invalid||dataService.fields.passwordAgain.control.invalid)||(dataService.fields.password.control.value!=''&&
dataService.fields.password.control.value!=dataService.fields.passwordAgain.control.value)"
                            (click$)="dataService.mailSignClick$.next()"
  >Sign up
  </app-brand-primary-button>
</div>