<div class="col gap1"
>
  <lib-hero-content-card titleNormal="Racks"
                         description="Keep track of your racks, check if your modules fit, and share them with your friends"
                         class="racksBG"
                         icon="view_stream"
                         id="racks"
  >
    <div class="col gap1"
    >
      <div class="row gap1 auto-left"
      >
        <app-brand-primary-button (click$)="dataService.addRack$.next()"
                                  fxLayoutAlign="end"
        >Create rack
        </app-brand-primary-button>
      </div>
      <mat-divider inset="true"></mat-divider>
      <ng-container *ngIf="(dataService.rackData$|async) as data">
        <app-rack-list fxFlex="grow"
                       [data$]="dataService.rackData$"
                       [showSearch]="true"
                       [viewConfig]="rackMinimalViewConfig"
                       *ngIf="data.length>0 else voidData"
        ></app-rack-list>
      </ng-container>
  
      <lib-auto-update-loading-indicator [data$]="dataService.rackData$"
                                         [updateData$]="dataService.updateRackData$"
      ></lib-auto-update-loading-indicator>
    </div>
  </lib-hero-content-card>

</div>

<ng-template #voidData>
  <div fxFlex="100">
    <mat-card-subtitle fxLayoutAlign="center center">It looks like you haven't created any racks
                                                     yet.
                                                     Try adding your first!
    </mat-card-subtitle>
  </div>
</ng-template>