(() => {
  "use strict";

  var e = {
      d: (n, t) => {
        for (var o in t) e.o(t, o) && !e.o(n, o) && Object.defineProperty(n, o, {
          enumerable: !0,
          get: t[o]
        });
      },
      o: (e, n) => Object.prototype.hasOwnProperty.call(e, n),
      r: e => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(e, "__esModule", {
          value: !0
        });
      }
    },
    n = {};
  e.r(n), e.d(n, {
    default: () => t
  });
  const t = "precision mediump float;\n\nvarying vec4 v_color;\nvarying vec2 v_normal;\nvarying float v_thickness;\n\nconst float feather = 0.001;\nconst vec4 transparent = vec4(0.0, 0.0, 0.0, 0.0);\n\nvoid main(void) {\n  float dist = length(v_normal) * v_thickness;\n\n  float t = smoothstep(\n    v_thickness - feather,\n    v_thickness,\n    dist\n  );\n\n  gl_FragColor = mix(v_color, transparent, t);\n}\n";
  module.exports = n;
})();