  <div class="col">
    <div
      class="root col"
    >
      <!--      all equidistant-->
      <div fxFlex="auto"
           class="gap2"
           fxLayout="row wrap"
           fxLayout.lt-sm="column"
      >
        <div fxFlex="auto"
             fxFlex.lt-sm="auto">
          <div class="col gap1">
            <app-label-value-showcase label="App last updated">{{ data.timestamp|timeago }}</app-label-value-showcase>
            <app-label-value-showcase label="Version">{{ data.version }}</app-label-value-showcase>
          </div>
        </div>

        <div fxFlex="auto"
             fxFlex.lt-sm="auto"
        >
          <app-label-value-showcase label="New in v4.0"
                                    [bigText]="false"
          >
            <div class="col gap1">
              <div>• Panel images for modules in details and search</div>
              <div>• Panel images in rack view</div>
              <div>• Add modules to rack from below the rack</div>
              <div>• Submit multiple panels for a module</div>
              <div>• Multiple UX improvements</div>
              
            </div>
          </app-label-value-showcase>
        </div>
        <div fxFlex="auto"
             fxFlex.lt-sm="auto"
        >
          <app-label-value-showcase label="Navigation"
                                    [bigText]="false"
          >
            <div class="col gap1">
              <div>• <a href="/home">Home</a></div>
              <div>• <a href="/modules/browser">Modules</a></div>
              <div>• <a href="/racks/browser">Racks</a></div>
              <div>• <a href="/patches/browser">Patches</a></div>
              <div>• <a href="/user/area">User Area</a></div>
              <div>• <a href="/user/account">Account</a></div>
              <div>• <a href="/modules/add">Submit a module</a></div>
            </div>
          </app-label-value-showcase>
        </div>
        <div fxFlex="auto"
             fxFlex.lt-sm="auto"
        >
          <app-label-value-showcase label="Need help?"
                                    [bigText]="false"
          >
            <div class="col gap1">
              <div>
                • Check out the <a href="https://docs.patcher.xyz/what-is-this/readme" target="_blank">documentation</a>
              </div>
              <div>
                • Join the <a href="https://discord.com/invite/JNy2HTb5ru" target="_blank">Discord</a> server
              </div>
              <div>
                • <a href="https://patcher.statuspage.io/" target="_blank">Status page</a>
              </div>
            </div>
          </app-label-value-showcase>
        </div>
        <div fxFlex="auto"
             fxFlex.lt-sm="auto"
        >
          <app-label-value-showcase label="Follow us"
                                    [bigText]="false"
          >

            <div class="col gap1">
              <div>
                • <a href="https://www.facebook.com/patcher.xyz" target="_blank">Facebook</a>
              </div>
              <div>
                • <a href="https://www.youtube.com/@patcher-xyz" target="_blank">YouTube</a>
              </div>
            </div>
          </app-label-value-showcase>
        </div>

        <div fxFlex="auto"
             fxFlex.lt-sm="auto"
        >
          <app-label-value-showcase label="Learn Patcher"
                                    [bigText]="false"
          >
            <div class="col gap1">
              <div>
                • <a href="https://docs.patcher.xyz/what-is-this/readme" target="_blank">Patcher Quick Start</a>
              </div>
              <div>
                • <a href="https://docs.patcher.xyz/the-project/the-project" target="_blank">FAQ</a>
              </div>
              <div>
                • <a href="https://docs.patcher.xyz/learn-patcher.xyz/modules" target="_blank">Modules</a>
              </div>
              <div>
                • <a href="https://docs.patcher.xyz/learn-patcher.xyz/racks" target="_blank">Racks</a>
              </div>
              <div>
                • <a href="https://docs.patcher.xyz/learn-patcher.xyz/patches" target="_blank">Patches</a>
              </div>
              <div>
                • <a href="https://docs.patcher.xyz/learn-patcher.xyz/collection" target="_blank">Collection</a>
              </div>
              <div>
                • <a href="https://docs.patcher.xyz/learn/modular-glossary" target="_blank">Learn Modular Concepts</a>
              </div>
            </div>
          </app-label-value-showcase>
        </div>

        <div class="col gap1">
          <app-label-value-showcase label="Want to follow the project and suggest features?">

            <a href="https://discord.com/invite/JNy2HTb5ru">
              Join the Discord server
            </a>
          </app-label-value-showcase>
          <app-label-value-showcase label="Want to chat?">
            <a
              href="mailto:europatcher@outlook.com?subject=My%20great%20idea&body=Hello!%20I%20have%20an%20awesome%20idea%3A%0D%0A...">Contact
              us</a>
          </app-label-value-showcase>
          <app-label-value-showcase label="Partners and Sponsors"
          >
            <div class="col gap1">
              <a href="mailto:europatcher@outlook.com" target="_blank">Become a Partner</a>
            </div>
          </app-label-value-showcase>

        </div>

      </div>
      <mat-divider inset="true"></mat-divider>
      <div class="row gap1"
           fxLayout.lt-sm="column"
      >
        <app-lottie-container fxLayoutAlign="start end"
                              fxFlex="100"
                              class="transparentize-half"
                              style="max-width: 20rem; margin:-.75rem;"
                              [options]="{path: '/assets/json/lottie/QR Border Icon.json', loop: true, autoplay: true}"
        ></app-lottie-container>
        <h3 fxLayoutAlign="end center"
            fxFlex="grow"
            class="tagline"
        >
          THE MODERN WAY TO MANAGE EVERYTHING MODULAR</h3>
      </div>
      
      <div style="text-align: center; padding-top: 2rem; color: rgba(0,0,0,0.87);">
        Patcher's code is public and open-source
        <br>
        © 2024 Vladyslav Yakovenko
        <br>
        License: AGPL-3.0
        <br>
        <a href="https://www.gnu.org/licenses/agpl-3.0.html" target="_blank" style="margin-right: .5rem;">View
          License</a>
        <a href="https://github.com/Polyterative/Patcher" target="_blank">Contribute on GitHub</a>
      
      </div>
    </div>

  </div>