<ng-container *ngIf="(dataService.manualsData$|async) as data">
  <div class="col gap1"
       *ngIf="data.length>0"
>
    <lib-hero-content-card description="Available manuals for your modules. Click to open"
                         class="manualsBG"
                         icon="book"
                         id="manuals"
  >
    <div class="col gap1"
    >
      <ng-container *ngIf="data.length>0;else voidData">
      
      <div class="rowwrap gap1"
        >
          
          <ng-container *ngFor="let item of data">
            
            <button mat-raised-button
                    (click)="openManual(item.manualURL)">{{ item.name }}
            </button>
          
          </ng-container>
        
        </div>
      
      
      </ng-container>
      
      <lib-auto-update-loading-indicator [data$]="dataService.manualsData$"
                                         [updateData$]="dataService.updateManualsData$"
      ></lib-auto-update-loading-indicator>
    </div>
  </lib-hero-content-card>
</div>
</ng-container>

<ng-template #voidData>
  <mat-card-subtitle
    style="padding: 1rem; align-self: center; text-align: center; width: 100%"
  >It looks like you haven't got any modules with manuals
    yet
    </mat-card-subtitle>
</ng-template>