<ng-container *ngIf="{
  rowedRackedModules:((dataService.rowedRackedModules$|async)),
  isCurrentRackPropertyOfCurrentUser:(dataService.isCurrentRackPropertyOfCurrentUser$|async),
  isCurrentRackEditable:(dataService.isCurrentRackEditable$|async),
  userRequestedSmallerScale:(dataService.userRequestedSmallerScale$|async)
  } as bag"
>
  <div class="col gap1"
  >
    <lib-auto-content-loading-indicator *ngIf="bag.rowedRackedModules==null;else yesdata"
                                        [skipFirstData]="true"
                                        [loadingLines]="5"
    >
    </lib-auto-content-loading-indicator>
  </div>
  
  <ng-template #yesdata>
    <lib-hero-content-card [description]="
                             (bag.isCurrentRackPropertyOfCurrentUser?(((bag.isCurrentRackPropertyOfCurrentUser&&bag.isCurrentRackEditable)?'Changes saved automatically / Right click on modules for more options / Add modules from below':'Unlock to make changes')):'')"
                           descriptionAlign="alignTextEnd"
                           class="transition-all-long"
                           [ngClass]="{
                           'editiableBG':bag.isCurrentRackPropertyOfCurrentUser&&bag.isCurrentRackEditable, 
                           'readOnlyBG':(bag.isCurrentRackPropertyOfCurrentUser&&(!bag.isCurrentRackEditable))
                           }"
    
    >
      <div class="col gap1 transition-all scroll custom-scrollbar"
           [ngClass]="{'rackLocked':!(bag.isCurrentRackPropertyOfCurrentUser&&bag.isCurrentRackEditable)}"
      >
        
        <lib-screen-wrapper [maxSize]="data.hp+'rem'"
        
        >
          <div class="col gap1"
          
          >
            
            <app-advice-tooltip *ngIf="dataService.isCurrentRackPrivate$|async"
                                [@enter]="{ value: '', params: { delay: 250}}"
            
            >
              This rack private. It won't be listed in search but will be accessible to anyone who has
              the URLm
            </app-advice-tooltip>
            <lib-clean-card [@enter]>
              <app-lib-showcase-grid [data]="[
    { label: 'Rack name', value: data.name, icon: 'label', size: 'auto' },
    { label: 'Rack rows', value: data.rows.toString(), icon: 'view_comfy', size: 'auto' },
    { label: 'Modules', value: (bag.rowedRackedModules | totalModulesOfRack).toString(), icon: 'widgets', size: 'auto' },
    { label: 'HP', value: data.hp.toString(), icon: 'straighten', size: 'auto' },
    { label: 'HP used', value: (bag.rowedRackedModules | totalHpOfRack).toString(), icon: 'crop_5_4', size: 'auto' },
    { label: 'HP available', value: (data.hp * data.rows - (bag.rowedRackedModules | totalHpOfRack)).toString(), icon: 'crop_free', size: 'auto' },
    { label: 'Rack Utilization', value: calculateRackUtilization(data.hp, data.rows, bag.rowedRackedModules | totalHpOfRack), icon: 'bar_chart', size: 'auto' }
  ]">
              </app-lib-showcase-grid>
              
              <!--              { label: '+12V mA', value: '12000', icon: 'bolt' },-->
              <!--               { label: '-12V mA', value: '12000', icon: 'bolt' },-->
              <!--              { label: '+5V mA', value: '12000', icon: 'bolt' }-->
            </lib-clean-card>
            
            
            <div class="row gap1">
              
              <mat-slide-toggle [checked]="dataService.shouldShowPanelImages$|async"
                                (change)="dataService.shouldShowPanelImages$.next(!dataService.shouldShowPanelImages$.value)"
                                style="align-self: center;"
              >
                <div style="cursor: pointer;">
                  Use images
                </div>
              </mat-slide-toggle>
              <mat-slide-toggle [checked]="dataService.showModuleCounters$|async"
                                (change)="dataService.showModuleCounters$.next(!dataService.showModuleCounters$.value)"
                                style="align-self: center;"
              >
                <div style="cursor: pointer;">
                  Count modules
                </div>
              </mat-slide-toggle>
              <mat-slide-toggle [checked]="dataService.userRequestedSmallerScale$|async"
                                (change)="dataService.userRequestedSmallerScale$.next(!dataService.userRequestedSmallerScale$.value)"
                                style="align-self: center;"
              >
                <div style="cursor: pointer;">
                  Reduce scale
                </div>
              </mat-slide-toggle>
              
              <!--              create space between these-->
              
              <div fxFlex="1 1 auto"></div>
              
              <app-brand-primary-button style="padding: 1rem 0;"
                                        *ngIf="bag.isCurrentRackPropertyOfCurrentUser"
                                        (click$)="dataService.requestRackEditableStatusChange$.next()"
              >
                {{ bag.isCurrentRackEditable ? "Lock rack" : "Unlock rack" }}
              </app-brand-primary-button>
              <app-brand-primary-button style="padding: 1rem 0;"
                                        (click$)="dataService.downloadRackImageToUserComputer$.next()"
                                        *ngIf="(bag.rowedRackedModules|totalHpOfRack)>0"
                                        matTooltip="Will download an image file of the rack to your computer"
                                        matTooltipPosition="above"
                                        matTooltipShowDelay="1000"
                                        
              >
                Download jpeg
              </app-brand-primary-button>
              <app-brand-primary-button style="padding: 1rem 0;"
                                        (click$)="dataService.updateRackImagePreview$.next()"
                                        *ngIf="bag.isCurrentRackPropertyOfCurrentUser&&(bag.rowedRackedModules|totalHpOfRack)>0"
                                        matTooltip="Will update the rack preview image to help you identify it in the lists"
                                        matTooltipPosition="above"
                                        matTooltipShowDelay="1000"
                                        
              >
                Update preview
              </app-brand-primary-button>
            </div>
            
            
            <app-rack-visual-model [rackData]="data"
                                   [rowedRackedModules]="bag.rowedRackedModules"
                                   [isCurrentRackPropertyOfCurrentUser]="bag.isCurrentRackPropertyOfCurrentUser"
                                   [isCurrentRackEditable]="bag.isCurrentRackEditable"
                                   [rackDetailDataService]="dataService"
                                   [moduleRightClick$]="moduleRightClick$"
                                   class="transition-all-long"
                                   [ngClass]="{
                                     'smallerScale':bag.userRequestedSmallerScale
                                   }"
            ></app-rack-visual-model>
          </div>
        </lib-screen-wrapper>
      </div>
    </lib-hero-content-card>
    <app-general-context-menu></app-general-context-menu>
  </ng-template>
  <ng-template #noData>
    <div fxFlex="100">
      <div class="col gap1 animate-fadein-slowest"
      >
        <mat-card-subtitle fxLayoutAlign="center start"
                           style="padding: 1rem"
        >No modules in rack yet
        </mat-card-subtitle>
      </div>
    </div>
  </ng-template>

</ng-container>


<!--<div id="download" style="height: 0">-->
<!--  <img #canvas>-->
<!--  <a #download></a>-->
<!--</div>-->