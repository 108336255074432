<div class="parent parentPad"
>
  <div class="col bg item">
    <div class="top">
      <div class="row"
           style="gap:.5rem"
      >
        <div class="circle"
        ></div>
        <div class="circle"
        ></div>
        <div class="circle"
        ></div>
        <div fxFlex="grow"></div>
      </div>
    </div>
    <div style="width:100%;"
    >
      <ng-content></ng-content>
    </div>
    <!--    <div fxFlex="100"-->
    <!--         class="bottom"-->
    <!--    >-->
    <!--    </div>-->
  </div>
</div>