<ng-container *ngIf="{
  patchEditingPanelOpenState:(patchingService.patchEditingPanelOpenState$|async),
  filteredData:(filteredData$|async),
  data:(data$|async)
  
  } as bag"
>
  
  <lib-auto-content-loading-indicator *ngIf="bag.data==null;else data"
                                      [skipFirstData]="true"
                                      [loadingLines]="5"
  >
  </lib-auto-content-loading-indicator>
  
  
  <ng-template #data>
    <div class="col gap1">
      <div class="row gap1"
           *ngIf="showSearch"
      >
        <app-local-data-filter></app-local-data-filter>
      </div>
      <flexbox-row-fast
                        [ngClass]="{'limit-height':encloseVertically}"
      >
        <ng-container *ngFor="let item of (showSearch?bag.filteredData:bag.data); let i = index"
        >
          <lib-clean-card [@enter]="{ value: '', params: { delay: (i * 25)+50}}"
                          [@leave]="{ value: '', params: { delay:  0 }}"
          >
            <app-module-minimal *ngIf="!bag.patchEditingPanelOpenState; else fullData"
                                [data]="item"
                                [viewConfig]="viewConfig"
            ></app-module-minimal>
          </lib-clean-card>
          <ng-template #fullData>
            <app-module-composite [data]="item"
                                  [viewConfig]="viewConfig"
            ></app-module-composite>
          </ng-template>
        </ng-container>
      </flexbox-row-fast>
    </div>
  </ng-template>

</ng-container>