<app-toolbar></app-toolbar>
<div class="main">
  <div class="col"
  >
    <router-outlet></router-outlet>
    <!--    <lib-screen-wrapper>-->
    <app-faq></app-faq>
    <app-footer></app-footer>
    <!--    </lib-screen-wrapper>-->
  </div>
</div>
