<div class="col gap1"
>
  <lib-hero-content-card titleNormal="Modules"
                         description="Modules added to your collection will be visible in the patch builder"
                         class="modulesBG"
                         icon="view_module"
                         id="modules"
  >
    <ng-container *ngTemplateOutlet="content;">
    </ng-container>
  </lib-hero-content-card>
</div>

<ng-template #voidData>
  <div fxFlex="100">
    <mat-card-subtitle fxLayoutAlign="center center">So far, you haven't added any modules to your
                                                     collection. Try adding a few!
    </mat-card-subtitle>
  </div>
</ng-template>


<ng-template #content>
  <div class="col gap1"
  >
    <ng-container *ngIf="!(userModulesComponentViewConfig.hideAddModulesButton)">
      <div class="row gap1 auto-left"
      >
        <app-brand-primary-button routerLink="/modules/add"
                                  fxLayoutAlign="end"
        >Submit NEW
        </app-brand-primary-button>
        <app-brand-primary-button routerLink="/modules/browser"
                                  fxLayoutAlign="end"
        >Add modules to collection
        </app-brand-primary-button>
      </div>
      <mat-divider inset="true"></mat-divider>
    </ng-container>
  
    <lib-auto-update-loading-indicator [data$]="dataService.modulesData$"
                                       [updateData$]="dataService.updateModulesData$"
    >
      <ng-container *ngIf="(dataService.modulesData$|async) as data">
        <app-module-list fxFlex="grow"
                         [data$]="dataService.modulesData$"
                         [viewConfig]="modulesViewConfig"
                         [showSearch]="true"
                         [encloseVertically]="encloseVertically"
                         *ngIf="data.length>0 else voidData"
        ></app-module-list>
      </ng-container>
    </lib-auto-update-loading-indicator>
  
  </div>
</ng-template>