<div fxFlex="100"
     fxLayout="column"
     fxLayoutGap="4rem"
>
  <div fxFlex="100"
       fxLayout="row"
       fxLayoutGap="4rem"
  >
    <!--        <app-brand-primary-button innerFlex="grow"-->
    <!--                                  [disabled]="true"-->
    <!--                                  (click$)="backend.import.manufacturers()"-->
    <!--        >import manu-->
    <!--        </app-brand-primary-button>-->
    <!--        <app-brand-primary-button innerFlex="grow"-->
    <!--                                  [disabled]="true"-->
      <!--                                  (click$)="backend.import.modules()"-->
      <!--        >import modules-->
      <!--        </app-brand-primary-button>-->
      <!--      <app-brand-primary-button innerFlex="grow"-->
      <!--                                (click$)="devToProd$.next()"-->
      <!--      >dev To Prod-->
      <!--      </app-brand-primary-button>-->
  
      <app-brand-primary-button innerFlex="grow"
                                (click$)="click$.next()"
      >DO
      </app-brand-primary-button>


    </div>
  <!--    <div fxFlex="100"-->
  <!--         fxLayout="row"-->
  <!--         fxLayoutGap="4rem"-->
  <!--    >-->
  <!--        <app-brand-primary-button innerFlex="grow"-->
  <!--                                  (click$)="downloadDev$.next()"-->
  <!--        >download Dev-->
  <!--        </app-brand-primary-button>-->
  <!--        -->
  <!--        <app-brand-primary-button innerFlex="grow"-->
  <!--                                  (click$)="downloadProd$.next()"-->
  <!--        >download Prod-->
  <!--        </app-brand-primary-button>-->
  <!--    -->
  <!--    </div>-->
  <!--    <div fxFlex="100"-->
  <!--         fxLayout="row"-->
  <!--         fxLayoutGap="4rem"-->
  <!--    >-->
  <!--        <app-brand-primary-button innerFlex="grow"-->
  <!--                                  (click$)="downloadDev$.next()"-->
  <!--        >download Dev-->
  <!--        </app-brand-primary-button>-->
  <!--        -->
  <!--        <app-brand-primary-button innerFlex="grow"-->
  <!--                                  (click$)="downloadProd$.next()"-->
  <!--        >download Prod-->
  <!--        </app-brand-primary-button>-->
  <!--    -->
  <!--    </div>-->
</div>
